._abstractVideoSwiper {
  height: 270px;
  .swiper-wrapper {
    .swiper-slide {
      .history-item {
        height: 100%;
        .actionbox__item {
          height: 100%;
          a {
            height: 100%;
            width: 100%;
            display: block;
            background-position: center center;
            background-size: cover;
            color:$white;
            position: relative;
            overflow: hidden;

            span.videoDescription {
              display: block;
              height:100%;
              width: 100%;
              padding: 20px;
              font-weight: bold;
              font-size: 35px;
              line-height: 42px;
            }

            &:after {
              content:"";
              height: 42px;
              width: 42px;
              background: url(/public/images/play.svg) center center no-repeat;
              background-size: cover;
              display: block;
              position: absolute;
              bottom: 2rem;
              right: 2rem;
            }
            &:hover {
              &:after {
                -webkit-animation: moveLeftToRight 0.5s ease;
                -moz-animation: moveLeftToRight 0.5s ease;
                -o-animation: moveLeftToRight 0.5s ease;
                animation: moveLeftToRight 0.5s ease;
              }
            }

          }
        }
      }
    }
  }
}