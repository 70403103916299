table.table-clean {

  &.padding-10 {
    thead {
      tr {
        th {
          padding-bottom: 5px;
        }
      }
    }
    td, th {
      padding-left: 10px;
      &:first-child {
        padding-left: 0;
      }
    }
  }

}