table.table-gray {

  margin-top:  1.786rem;
  width: 100%;
  border-collapse: initial;

  thead {
    tr {
      th {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 20px;
        &.no-padding-left {
          padding-left: 0;
        }
        &.no-padding-right {
          padding-right: 0
        }
      }
    }
  }

  tbody {
    tr {
      td {
        background: #e1e3e7;
        border-top:5px solid white;
        border-bottom:5px solid white;
        height:50px;
        padding-left: 15px;
        padding-right: 15px;
        &.no-padding-left {
          padding-left: 0;
        }
        &.no-padding-right {
          padding-right: 0
        }
      }
      &.strong td {
        font-weight: bold
      }
    }
  }

}