/* ==========================================================================
   Colors
   ========================================================================== */
$black: #000000;
$white: #ffffff;

$yellow: #ffd200;

$blue: #254a91;
$blue-bright1: #4565a1;
$blue-bright2: #6984b4;

$grey: #e0e2e7;
$greyInput: #ecedf0;

$red: #cf0815;

$green: #5bbc1b;

/* ==========================================================================
   Variables
   ========================================================================== */
$fontDefault: "Helvetica Neue LT", "Helvetica Neue", Helvetica, Arial, sans-serif;