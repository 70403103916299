main.orders {
  td.cc-icon {
    font-size: 20px;
    width: 30px;
  }
  #service-center-orders-button {
    display: none;
  }
  i {
    font-size: 20px;
    &.fa {
      transition: transform .2s;
    }
  }
  a:hover {
    color:$blue;
    i.fa {
      transform: scale(1.2);
    }
  }
}